function makePostBoxSameHeight() {
	if ($(window).width() > 991) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".summary", ".img-thumbnail", ".name"];

		$.each( elementArr, function( key, value ) {
		  highestBox = 0;
			$('#content .box_content .box_post ' + value).height('auto');

			$('#content .box_content .box_post ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('#content .box_content .box_post ' + value).height(highestBox);
		});
	} 
}

$(document).ready(function() {
	// Bọc các ảnh trong bài viết với thuộc tính fancybox gallery
	$(".detail_post img").each(function(){
		var check_href = $(this).parent().is('a');
		if(!check_href) {
			var src = $(this).attr('src');
			$(this).wrap('<a href="'+src+'" data-fancybox="group"></a>');
		} else {
			http_href = $(this).parent().attr('href');
			http = http_href.substring(0, 4);
			if(http != 'http') {
				http_href = 'http://'+http_href;
				$(this).parent().attr('href',http_href);
			}
			$(this).parent().attr('target','_blank');
		}
	});

	$("[data-fancybox]").fancybox({
		slideShow: true,
		loop : true
	});

	$.fancybox.defaults.hash = false;
	// Căn các khối tin tức bằng nhau khi kéo cửa sổ
	$(window).on('load', function() {			// Khi các ảnh được load hết
		makePostBoxSameHeight();
	});

	$(window).on('resize', function() {		// Khi thay đổi kích thước cửa số
		makePostBoxSameHeight();
	});
});
